@import "./normalize";
@import "./intro";
@import "./locomotive-scroll";
@font-face {
  font-family: 'Bandeins';
  src: url('../fonts/BandeinsSansVariableGX.ttf')  format('truetype')
}
$navy:#1b2037;
$red:#ff1127;
$gray:#7c7c7c;
$ease-out-expo:cubic-bezier(0.19, 1, 0.22, 1);
body{
  font-family: 'Bandeins',sans-serif;
  font-weight: 100;
  font-size: 2em;
  font-variation-settings: "wght" 600;
  
}
body.scroll-indicator-hover{
  cursor:none;
}
.hero{
  background-color: $navy;
  min-height: 100vh;
  color:#fff;
  position: relative;
  display: flex;
  align-items: center;
  .scroll-indicator{
    position: absolute;z-index: 2;
    bottom:0;
    left:0 ;
    padding:3em;
    .outline{
      height:55px;
      border:3px solid rgba(#fff,0);
      
      width:40px;
      border-radius:26px;
      overflow: hidden;
      position: relative;
      margin-left: -10px;
      margin-bottom: -10px;
      transition:1s border-color $ease-out-expo, 1s height $ease-out-expo;
    }
    .arrow{
      position: absolute;
      left: 7px;
      top: 0px;
    }
    .icon{
      font-size: 1.4em;
      font-variation-settings: "wght" 200;
    }
    @keyframes icon-move {
      0% { transform: translateY(0px);}
      50% {transform: translateY(80px);}
      51% {transform: translateY(-80px);}
      100% { transform: translateY(0px);}
    }
    body.scroll-indicator-hover &{
      .outline{
        height:75px;
        border-color:#fff;
      }
      // .icon{
      //   animation: icon-move 0.8s infinite ease-in-out;
      //   animation-fill-mode: both;
      // }
    }
  }
  .bg{
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $navy;
    display: none;
    html.has-scroll-smooth &{
      display: block;
    }

    &:after{
      content:'';
      display: block;
      height: 100vh;
      width: 100vw;
      background-color: $red;
      top: 100%;
      position: absolute;
    }
  }
  h1{
    .word{
      display: inline-block;
      overflow: hidden;
      line-height: 1.2;
      .word-inner{
        display: block;
      }
    }
  }
}
.container{
  width: 1400px;
  margin: auto;
  position: relative;
  z-index: 1;
}
h1,h2,h3{
  line-height: 1.6;
  font-weight: 100;
  color:$red;
  .word{
    display: inline-block;
    overflow: hidden;
    line-height: 1.23;
    .word-inner{
      display: block;
    }
  }
}
a {
  color:$red;
  text-decoration: none;
}
.scroll-progress{
  position: fixed;
  z-index: 4;
  width: 8px;
  right:0;
  bottom:0;
  top:0;
 // background-color: $navy;
  .bar{
    height: 0px;
    background-color: darken(#fff,15%);
  }
}
header{
  position: absolute;
  padding-top: 3em;
  padding-left: 3em;
  top: 0;
  left: 0;
  h1{
    font-size: 2em;
    height: 100vh;
  }
}
footer{
  padding: 3em 3em 4em;
  p{
    font-size: 0.4em;
    line-height: 1.6;
    color:$gray;
    margin-top: 4em;
  }
  &:before{
    content:'';
    display: block;
    margin-bottom: 3em;
    border-top:1px solid lighten($gray,40%);
  }
}
.footer-logo{
  color:$navy;
  display: inline-block;
  .line{
    display: block;
    background-clip: text;
    color: transparent;
    background: linear-gradient(90deg,  $navy 49%,$red 49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 220%;
    background-position: 0% 0;
    transition:0.3s background-position  $ease-out-expo;
  }
  &:hover{
    .line{
      background-position: -90% 0;
    }
  }

}
.logo{
  display: block;
  font-size: 0.95em;
  font-variation-settings: "wght" 700;
  position: relative;


  .line{
    display: block;
    background-clip: text;
    color: transparent;
    background: linear-gradient(90deg,  $red 49%,#fff 49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 220%;
    background-position: 0% 0;
    transition:0.3s background-position  cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover{
    .line{
      background-position: -90% 0;
    }
  }


}
.section {
  p{
    font-size: 0.75em;
    line-height: 1.8;
    max-width: 470px;
    margin-top: 3em;
    font-variation-settings: "wght" 300;
  }
}
.mission{
  background-color: $red;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  .container{
    z-index: 4;
  }
  .bg{
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $red;
    display: none;
    html.has-scroll-smooth &{
      display: block;
    }
    
    &:after{
      content:'';
      display: block;
      height: 100vh;
      width: 100vw;
      background-color: $red;
      top: 100%;
      position: absolute;
    }
  }
  h2{
    color:$navy;
    font-size: 2em;
    .word{
      display: inline-block;
    }
  }
  p{
    color:#fff;
  }
}
.contact{
  .container{
    z-index: 0;
  }
  h3{
    color:$navy;
    max-width: 333px;
    font-size: 2em;
  }
  min-height: 100vh;
  display: flex;
  align-items: center;
  a{
    font-variation-settings: "wght" 500;
    position: relative;
    display: inline-block;
    
    &:before{
      content:'';
      position: absolute;
      bottom:3px;
      height:2px;
      width:100%;
      background-color: $navy;
      display: block;
      transform: scaleX(0);
      transform-origin: 100% 0%;
      transition:0.3s transform cubic-bezier(.05,.6,.11,.98);
    }
    &:hover{
      &:before{

        transform: scaleX(1);
        transform-origin: 0% 0%;
        //animation:link-underine 0.5s cubic-bezier(.81,.02,.11,.98);
        //border-bottom:1px solid $navy;
      }

    }
    @keyframes link-underline {
      from {
        transform: scaleX(0);
      }
    
      to {
        transform: scaleX(1);
      }
    }
  }
}
@import "./responsive";