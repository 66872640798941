
.intro-container{
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.intro {
    width: 499px;
    height: 411px;
    background-image: url(../img/logoani.png);
    background-repeat: no-repeat;
    display: block;
    transform: scale(0.75);
}

.intro[data-frame="00"] {
    background-position: -5px -5px;
}

.intro[data-frame="01"] {
    background-position: -514px -5px;
}

.intro[data-frame="02"] {
    background-position: -1023px -5px;
}

.intro[data-frame="03"] {
    background-position: -1532px -5px;
}

.intro[data-frame="04"] {
    background-position: -2041px -5px;
}

.intro[data-frame="05"] {
    background-position: -2550px -5px;
}

.intro[data-frame="06"] {
    background-position: -3059px -5px;
}

.intro[data-frame="07"] {
    background-position: -3568px -5px;
}

.intro[data-frame="08"] {
    background-position: -5px -426px;
}

.intro[data-frame="09"] {
    background-position: -514px -426px;
}

.intro[data-frame="10"] {
    background-position: -1023px -426px;
}

.intro[data-frame="11"] {
    background-position: -1532px -426px;
}

.intro[data-frame="12"] {
    background-position: -2041px -426px;
}

.intro[data-frame="13"] {
    background-position: -2550px -426px;
}

.intro[data-frame="14"] {
    background-position: -3059px -426px;
}

.intro[data-frame="15"] {
    background-position: -3568px -426px;
}

.intro[data-frame="16"] {
    background-position: -5px -847px;
}

.intro[data-frame="17"] {
    background-position: -514px -847px;
}

.intro[data-frame="18"] {
    background-position: -1023px -847px;
}

.intro[data-frame="19"] {
    background-position: -1532px -847px;
}

.intro[data-frame="20"] {
    background-position: -2041px -847px;
}

.intro[data-frame="21"] {
    background-position: -2550px -847px;
}

.intro[data-frame="22"] {
    background-position: -3059px -847px;
}

.intro[data-frame="23"] {
    background-position: -3568px -847px;
}

.intro[data-frame="24"] {
    background-position: -5px -1268px;
}

.intro[data-frame="25"] {
    background-position: -514px -1268px;
}

.intro[data-frame="26"] {
    background-position: -1023px -1268px;
}

.intro[data-frame="27"] {
    background-position: -1532px -1268px;
}

.intro[data-frame="28"] {
    background-position: -2041px -1268px;
}

.intro[data-frame="29"] {
    background-position: -2550px -1268px;
}

.intro[data-frame="30"] {
    background-position: -3059px -1268px;
}

.intro[data-frame="31"] {
    background-position: -3568px -1268px;
}

.intro[data-frame="32"] {
    background-position: -5px -1689px;
}

.intro[data-frame="33"] {
    background-position: -514px -1689px;
}

.intro[data-frame="34"] {
    background-position: -1023px -1689px;
}

.intro[data-frame="35"] {
    background-position: -1532px -1689px;
}

.intro[data-frame="36"] {
    background-position: -2041px -1689px;
}

.intro[data-frame="37"] {
    background-position: -2550px -1689px;
}

.intro[data-frame="38"] {
    background-position: -3059px -1689px;
}

.intro[data-frame="39"] {
    background-position: -3568px -1689px;
}

.intro[data-frame="40"] {
    background-position: -5px -2110px;
}

.intro[data-frame="41"] {
    background-position: -514px -2110px;
}

.intro[data-frame="42"] {
    background-position: -1023px -2110px;
}

.intro[data-frame="43"] {
    background-position: -1532px -2110px;
}

.intro[data-frame="44"] {
    background-position: -2041px -2110px;
}

.intro[data-frame="45"] {
    background-position: -2550px -2110px;
}

.intro[data-frame="46"] {
    background-position: -3059px -2110px;
}

.intro[data-frame="47"] {
    background-position: -3568px -2110px;
}

.intro[data-frame="48"] {
    background-position: -5px -2531px;
}

.intro[data-frame="49"] {
    background-position: -514px -2531px;
}

.intro[data-frame="50"] {
    background-position: -1023px -2531px;
}

.intro[data-frame="51"] {
    background-position: -1532px -2531px;
}

.intro[data-frame="52"] {
    background-position: -2041px -2531px;
}

.intro[data-frame="53"] {
    background-position: -2550px -2531px;
}

.intro[data-frame="54"] {
    background-position: -3059px -2531px;
}

.intro[data-frame="55"] {
    background-position: -3568px -2531px;
}

.intro[data-frame="56"] {
    background-position: -5px -2952px;
}

.intro[data-frame="57"] {
    background-position: -514px -2952px;
}

.intro[data-frame="58"] {
    background-position: -1023px -2952px;
}

.intro[data-frame="59"] {
    background-position: -1532px -2952px;
}

.intro[data-frame="60"] {
    background-position: -2041px -2952px;
}

.intro[data-frame="61"] {
    background-position: -2550px -2952px;
}

.intro[data-frame="62"] {
    background-position: -3059px -2952px;
}

.intro[data-frame="63"] {
    background-position: -3568px -2952px;
}

.intro[data-frame="64"] {
    background-position: -5px -3373px;
}

.intro[data-frame="65"] {
    background-position: -514px -3373px;
}

.intro[data-frame="66"] {
    background-position: -1023px -3373px;
}

.intro[data-frame="67"] {
    background-position: -1532px -3373px;
}

.intro[data-frame="68"] {
    background-position: -2041px -3373px;
}

.intro[data-frame="69"] {
    background-position: -2550px -3373px;
}

.intro[data-frame="70"] {
    background-position: -3059px -3373px;
}

.intro[data-frame="71"] {
    background-position: -3568px -3373px;
}

.intro[data-frame="72"] {
    background-position: -4077px -5px;
}

.intro[data-frame="73"] {
    background-position: -4077px -426px;
}

.intro[data-frame="74"] {
    background-position: -4077px -847px;
}

.intro[data-frame="75"] {
    background-position: -4077px -1268px;
}

.intro[data-frame="76"] {
    background-position: -4077px -1689px;
}

.intro[data-frame="77"] {
    background-position: -4077px -2110px;
}
