@media (max-width: 1600px) {
    .container{
        width: 1200px;
    }
    h1, .mission h2, .contact h3{
        font-size: 1.8em;
    }
}

@media (max-width: 1440px) {
    .container{
        width: 1024px;
    }
    // h1, .mission h2, .contact h3{
    //     font-size: 1.8em;
    // }
}

@media (max-width: 1280px) {
    .container{
        width: 800px;
    }
    // h1, .mission h2, .contact h3{
    //     font-size: 1.8em;
    // }
}

@media (max-width: 1024px) {
    .container{
        width: 640px;
    }
    h1, .mission h2, .contact h3{
        font-size: 1.5em;
    }
}

@media (max-width: 860px) {
    .container{
        width: 480px;
    }
    h1, .mission h2, .contact h3{
        font-size: 1.3em;
    }
    header {

        padding-top: 2.3em;
        padding-left: 2em;
        .logo{
            font-size: 0.8em;
        }
    }
    .footer-logo{
        font-size: 0.8em;
    }
    footer {
        padding: 2em 2em 2.75em;
    }
    footer:before {
        margin-bottom: 2em;
    }
    .hero .scroll-indicator{
        padding: 2em;
    }
}
@media (max-width: 700px) {
    .container{
        width: 380px;
    }
    h1, .mission h2, .contact h3{
        font-size: 1.1em;
    }
}
@media (max-width: 540px) {
    .container{
        width: auto;
        padding-left: 5%;
        padding-right: 5%;
    }
    h1{
        font-size: 1.025em;
    }
    .mission h2, .contact h3{
        font-size: 1.4em;
    }
    header {

        padding-top: 0.75em;
        padding-left: 0.75em;
        .logo{
            font-size: 0.65em;
        }
    }
    .footer-logo{
        font-size: 0.65em;
    }
    footer {
        padding: 0.75em 0.75em 0.9em;
    }
    .hero{
        padding-bottom: 3em;
        box-sizing: border-box;
    }
    .hero .scroll-indicator{
        padding: 5%;
        margin-bottom: 4em;
    }
    .is-mobile{
        .mission, .contact{
            min-height: auto;
            padding-top: 10%;
            padding-bottom: 8%;
        }
    }
    .contact h3{
        max-width: 230px;
    }
}